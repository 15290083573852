import axios from "axios";

export const LOGIN = "auth/login";
export const ROLE = "user/all";

export const USER = "user";
export const USER_INFO = "user-info";
export const USER_HIS = "user-history";
export const REGISTER = "auth/register";
export const COMMON = "SysConfigs";
export const USER_CONDITION = "UserCondition";
export const AWARD_REGUL = "AwardRegul";
export const LOG = "log";


export const Sytems_ALL = "SysConfigs/all";
export const Sytems_ID = "SysConfigs";

export const HISTORY_ALL = "history/all";
export const HISTORY_DETAIL = "historyDetail"

//--------------
const baseURL = "https://api.lucky-wheel.syp400.com/api/v1";

const API = axios.create({
  baseURL: baseURL,
  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
  },
});

const auth = localStorage.getItem("auth");
if (auth) {
  API.defaults.headers.common["Authorization"] =
    JSON.parse(auth).tokenType + " " + JSON.parse(auth).accessToken;

  API.interceptors.response.use(undefined, (err) => {
    const error = err.response;
    // if error is 401
    if (error.status === 401 || error.status === 403) {
      localStorage.clear();
      window.location.assign("/login");
    }
  });
}

export default API;
